.editServiceContainer{
    margin-bottom: 48px;
}

.editTitleContainer{
    margin-top: 29px;
}

.colorPickerButtonContainer{
    justify-content: center;
    display: flex;
    width: 100%;
}

.colorPickerButton{
    margin-top: 24px;
    margin-bottom: 24px;
    width: 350px;
    height: 48px;
    border-radius: 4px;
    border: none;
    font-family: Arial;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #fff;
}

.colorPicker{
    position: absolute;
    left: 20%;
    top: 250px;
}

.serviceOptionsContainer{
    
}

.serviceOptionsTabSwitcher{
    justify-content: center;
    display: flex;
}

.edit-service-switch-container{
    /* margin-top: 24px; */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 350px;
    display: flex;
}

.edit-service-switch-text{
    text-align: left;
    width: 90%;
    height: 32px;
    line-height: 32px;
    font-family: Arial;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #808080;
}

.edit-service-enable-description-container{
    margin-top: 6px;
    margin-bottom: 24px;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
}

.edit-service-enable-description-text{
    font-family: Arial;
    font-weight: normal;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    text-align: left;
    color: #808080;
}

.scheduleByConfigurationTab{
    width: 0; /*344.5px*/
    height: 48px;
    background: #00c4d8;
    text-align: center;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 48px;
    letter-spacing: 0.09em;
    text-align: center;
    color: #fff;
    border-radius: .25rem 0 0 0;
}

.scheduleByAppointmentTab{
    width: 689px; /*344.5px*/
    height: 48px;
    background: #00c4d8;
    text-align: center;
    text-align: center;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 48px;
    letter-spacing: 0.09em;
    text-align: center;
    color: #fff;
    border-radius: .25rem .25rem 0 0;
}

.selected{
    border-bottom: 2px solid white;
}

.serviceOptionsContent{
    width: 689px;
    height: fit-content;
    min-height: 448px;
    border: 1px solid #00c4d8;
}

.errorMessage{
    height: 20px;
    color: red;
    text-align: center;
    margin: 0px;
}

.disabled{
    color: #bfbfbf;
    pointer-events: none;
}

.editTitleAlternates{
    text-align: center;
}

.editTitleAlternatesEditButton{
    cursor: pointer;
    margin-left: 4px;
    color: #00c4d8;
    font-weight: bold;
}