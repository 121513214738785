.provider-edit-container{
	width: 281px;
    height: 30px;
}

.provider-edit-input-group{
    width: 100%;
    height: 30px;
    background: transparent;
}

.provider-prepend{
    width: 50px;
    height: 30px;
    justify-content:center;
    display: flex;
    background: #00c4d8;
    border: 0px;
}

.provider-prepend-label{
    font-family: Roboto;
    font-weight: bold;
    font-size: 10px;
    text-align: left;
    color: #fff;
    background: transparent;
    border: 0px;
}

/*TODO font-size should be 14px, but height seems to be controlled by that... Something is forcing it to 35px height instead of our required 30px*/
.provider-form-control{
    min-height: 30px;
    max-height: 30px;
    height: 30px !important;
    
    line-height: 30px;
    font-family: Roboto;
    font-size: 10px;
    text-align: left;
    color: #808080;
    border: 1px solid #00c4d8;
    border-radius: 0px;
}