.container{
    display: flex;
    position: fixed;
    width: 448px;
    height: 50px;

    background: #DADADA;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    bottom: 64px;
    left: 0;
    right: 0;

    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    line-height: 50px;
}

.prompt{
    animation: bounce .8s normal;
}

.mainText{
    flex-grow: 1;
    text-align: center;
    width: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-left: 24px;
    line-height: 50px;
    display: flex;
    align-items: center;

    color: #808080;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    width: 126px;
    height: 50px;
    border-left: 1px solid #808080;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    z-index: 11;
}

.buttonContainer:hover{
    background: #D0D0D0;
}

.buttonContainer:active{
    background: #C0C0C0;
}

.buttonText{
    flex-grow: 0;
    height: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #00C4D8;
}

.multiSelectOptionsContainer{
    position: fixed;
    bottom: 122px;
    left: calc((50% - (448px / 2)) + (156px * 2));
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.confirmationContainer{
    margin-right: 16px;
    display: flex;
    flex-grow: 1;
    height: 50px;
    justify-content: end;
}

.confirmationConfirm{
    font-weight: 400;
    font-size: 18px;
    padding-right: 10px;
    padding-left: 10px;
    height: 50px;
    line-height: 50px;
    color: #00C4D8;
    cursor: pointer;
}

.confirmationCancel{
    font-weight: 400;
    font-size: 18px;
    padding-right: 10px;
    padding-left: 10px;
    height: 50px;
    line-height: 50px;
    color: red;
    cursor: pointer;
}

.confirmationCancel:hover, .confirmationConfirm:hover{
    background: #a6a6a6;
    color: white;
}

@keyframes bounce {
    0% {
        transform: translateY(0%);
    }
    50%{
        transform: translateY(-20%);
    }
    100% {
        transform: translateY(0%);
    }
}