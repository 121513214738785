/*1px = 0.062rem*/
.rowTitle{
    font-family: Arial;
    font-weight: bold;
    font-size: 1.24rem;
    line-height: 1.86rem;
    text-align: left;
    color: #00c4d8;
    margin-left: .62rem;
}

.optionsTable{
    margin-top: 3.1rem;
    margin-left: 3.1rem;
}