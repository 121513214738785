.reasonDropdown{
    position: absolute;
    background: #ffffff;
    margin-top: 32px;
    padding: 8px;
    font-size: 14px;
    margin-left: -44px;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    z-index: 5;
}

.alertIcon{
    position: absolute;
    width: 16px;
    height: 16px;
    margin-left: -44px;
    margin-top: 16px;
    z-index: 5;
}

.alertIconHidden{
    composes: alertIcon;
    visibility: hidden;
}

.alertIconSelectable{
    composes: alertIcon;
    cursor: pointer;
}