/*1px = 0.062rem*/
.edit-role-access-container{
    margin: 5.704rem;
}

.edit-role-name-container{
    margin-bottom: 5.27rem;
}

.edit-role-access-title{
    margin-left: .868rem;
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    text-align: left;
    color: #00c4d8;
}

.edit-access-list-item{
    margin-top: 1.488rem;
    margin-bottom: 1.488rem;
    margin-left: .868rem;
    margin-right: .868rem;
}