/* We want to hide the arrows*/

input::-webkit-outer-spin-button,

input::-webkit-inner-spin-button {

  -webkit-appearance: none;

  margin: 0;

}


/* Firefox */

input[type=number] {

  -moz-appearance: textfield;

}

/**/


/**/

.delete-role{
	position: relative;
	height: 16px;
	width: 16px;
	color: #00c4d8;
	margin-right: 2px;
	cursor: pointer;
	margin-top:auto;
	margin-bottom: auto;
    z-index: 5;
	right: 54px
}

.duration{
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #808080;
    color: #808080;
    margin-left: 20px;
    margin-top: 6px;
    margin-right: 4px;
    font-size: 20px;
    width: 26px;
    height: 22px;
}

.duration-error{
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid red;
    color: red;
    margin-left: 20px;
    margin-top: 6px;
    margin-right: 6px;
    font-size: 20px;
    width: 26px;
    height: 22px;
}

.duration-title{
    color: #808080;
    font-size: 16px;
    width: 22px;
    height: 22px;
}

.duration-text{
    color: #808080;
    font-size: 16px;
    width: 22px;
    height: 22px;
}

.duration-text-error{
    font-size: 16px;
    width: 22px;
    height: 22px;
    color: red;
}