.loginContainer{
    position: relative;
    width: 100%;
    min-height: 517px;
    height: 100%;
    margin: auto;
    justify-content:center;
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
}

.loginContainer div + div{
    margin-top: 24px;
}

.loginContainer div + button{
    margin-top: 24px;
}

.loginLogoContainer{
    position: absolute;
    top: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.loginLogo{
    height: 40px;
}