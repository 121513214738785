.container{
    width: 292px;
    height: 105px;
    color: #808080;
}

.message{
    text-align: center;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
}

.image{
    margin-left: 73px;
    margin-top: 6px;
}

.buttonContainer{
    position: absolute;
    right: 16px;
    bottom: 8px;
    display: flex;
}

.cancelButton{
    margin-right: 16px;
    font: normal normal bold 14px/30px Arial;
    letter-spacing: 0px;
}

.nextButton{
    font: normal normal bold 14px/30px Arial;
    letter-spacing: 0px;
    color: #00C4D8;
}