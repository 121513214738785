.runScheduleDaysBeforeRow{
    width: 500px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.runScheduleDaysBeforeEndText{
    margin-left: 8px;
    font-size: 14px;
    color: #808080;
    height: 24px;
    line-height: 24px;
}

.runScheduleDaysBeforeDropdown{

}