.automatic-switch{
    margin: 10px;
	margin-right: 24px;
	position: absolute;
	right: 0px;
}
.generate-time-window{
	width: 572px;
    height: 78px;
    border: 1px solid #00C4D8;
	border-bottom: none;
	padding-top: 14px;
	margin-left: -5px;
}
.generate-time-title{
	font-size: 14px;
	color: #808080;
	margin-right: 32px;
	height: 24px;
	line-height: 24px
}
.patient-button-unselected{
	cursor: pointer;
	box-sizing: border-box;
	text-align: center;
	width: 274px;
	height: 48px;
	background-color: #F2F2F2;
	border: 1px solid #E2DFDF;
	border-radius: 4px;
	z-index: 1;
	margin-right: 8px;
	margin-left: 8px;
	line-height: 48px;
	color: #808080;
	font-size: 16px;
	font-family: "Roboto"
}
.patient-button-selected{
	cursor: pointer;
	box-sizing: border-box;
	text-align: center;
	width: 274px;
	height: 48px;
	background-color: #00C4D8;
	border: 1px solid #E2DFDF;
	border-radius: 4px;
	z-index: 1;
	margin-right: 8px;
	margin-left: 8px;
	line-height: 48px;
	color: white;
	font-size: 16px;
	font-family: "Roboto"
}

.dropdown-heading{
	border: 1px solid #00C4D8 !important;
	height: 42px !important;
}
.dropdown-heading-value{
	margin-top: 2px;
	font-family: "roboto";
	font-weight: bold;
	font-size: 10px;
}
.select-item{
	font-family: "Roboto";
	color: #808080;
	font-size: 14px
}
.select-list{
	font-family: "Roboto";
	color: #808080;
	font-size: 14px
}
.select-item input{
	height: 10px;
	margin-top: 0px;
}
.select-item span{
	padding: 0px !important;
	padding-left: 3px !important;
	padding-bottom: 1px !important;
}