.day-picker-window{
    width: 572px;
    height: 76px;
    padding: 0px;
    border-left: 1px solid #00C4D8;
    border-right: 1px solid #00C4D8;
    padding-top: 4px;
    margin-left: -5px;
}

.day-picker-preview{
    width: 572px;
    height: 200px;
    padding: 0px;
    border: 1px solid #00C4D8;
    padding-top: 4px;
    margin-left: -5px;
    border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.day-picker-intervals{
    width: 572px;
    padding: 0px;
    border-top: 1px solid #00C4D8;
    border-left: 1px solid #00C4D8;
    border-right: 1px solid #00C4D8;
    padding-top: 4px;
    padding-bottom: 24px;
    margin-left: -5px;
}

.day-picker-radio-selector{
    margin-right: 8px;
}

.day-picker-title{
    font-size: 12px;
    text-align: center;
    font-family: "Roboto";
    padding-top: 4px;
    padding-bottom: 4px;
}
.time-blocks-window{
    width: 572px;
    border: 1px solid #00C4D8;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-bottom: 24px;
}
.time-blocks-title{
    top: -1px;
    left: -1px;
    position: relative;
    height: 48px;
    width: 572px;
    background-color: #00C4D8;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-align: center;
    line-height: 48px;
    font-size: 14px;
    font-family: "Roboto";
    color: white
}
.time-blocks-add-button{
	width: 282px;
	height: 32px;
	background-color: #FFFFFF;
	border: 2px solid #989898;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    display: flex;
    justify-content: center
}
.time-picker-row{
    width: 282px;
    display: flex;
    padding-right: 332px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    margin-bottom: 12px;
}
.time-picker-delete{
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-left: 42px;
    opacity: 0.5
}
.info-text{
    width: 100%;
    text-align: center;
    margin-top: 20px;
}