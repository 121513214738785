.edit-container{
    justify-content:center;
    display: flex;
}

.edit-input-group{
    width: 350px;
    height: 50px;
    background: transparent;
}

.input-group-prepend, input-group-prepend-rounded{
    width: 100px;
    height: 50px;
    justify-content:center;
    display: flex;
    background: #00c4d8;
    border: 0px;
}

.input-group-prepend-rounded{
    border-radius: 4px 0 0 4px;
}

.input-group-text{
    font-family: Roboto;
    font-weight: bold;
    font-size: 10px;
    text-align: left;
    color: #fff;
    background: transparent;
    border: 0px;
}

.form-control, form-control-rounded{
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #808080;
    border: 1px solid #00c4d8;
    border-radius: 0px;
}

.form-control-rounded{
    border-radius: 0 4px 4px 0 !important;
}

.input-group>.form-control::placeholder{
    color: #B3B3B3 !important; 
}

.invisible{
    margin-top: 0px !important;
    height: 0px;
    background: transparent;
    color: transparent;
}