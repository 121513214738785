.appointmentPopupTitle{
    position:absolute;
    background-color: #f26868;
    top: 12px;
    left: 140px;
    height:20px;
    width:160px;
    text-align:center;
    font-size:12px;
    color:white;
    border-radius:8px;
}

.appointmentPopupTitleDropdown{
    composes: appointmentPopupTitle;
    cursor: pointer;
}

.appointmentPopupTitleDropdownOpen{
    composes: appointmentPopupTitleDropdown;
    background-color: #b34d4d;
}

.dropdownItem{
    background-color: #f26868;
    border-radius:8px;
    height:20px;
    width:160px;
}

.appointmentPopupTitleDropdown:after {
    content: '▼';
    color: white;
    right: 11px;
    top: 0;
    height: 20px;
    padding: 1px 0px 0px 8px;
    position: absolute;
    border: none;
    pointer-events: none;
}

.appointmentPopupTitleDropdownOpen:after {
    content: '▲';
    color: white;
    right: 11px;
    top: 0;
    height: 20px;
    padding: 0px 0px 0px 8px;
    position: absolute;
    border: none;
    pointer-events: none;
}

.dropdownWindow{

}