.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.alert-white {
  background-color:#fff;
  color:#2999fb;
  border-color: #fff;
  
  -webkit-box-shadow: 0 2px 3px rgba(0,0,0,.2);
  -moz-box-shadow: 0 2px 3px rgba(0,0,0,.2);
  box-shadow: 0 2px 3px rgba(0,0,0,.2);
}
