/*1px = 0.062rem*/
.option-container, .option-container-selected{
    width: 15.872rem;
    height: 12.71rem;
    border-radius: 4px;
}

.option-container, .option-container-selected{
    background: #f2f2f2;
    border: 1px solid #cecece;
    justify-content:center;
    display: flex;
    flex-direction: column;
}

.option-container-selected{
    background: #fff;
    border: 1px solid #cecece;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
}

.option-image{
    
}

.option-title, .option-title-selected{
    font-family: Arial;
    font-weight: bold;
    font-size: 1.178rem; /*TODO change this to 20? Makes text wrap currently*/
    
    line-height: 1.86rem;
    text-align: center;
    color: #808080;
    margin-top: 1.488rem;
}

.option-title-selected{
    color: #00c4d8;
}
