.role-dropdown-fallback, .role-dropdown-fallback-empty{
    width: 350px;
    height: 50px;
    background: transparent;
	border: 1px solid #00c4d8;
	margin: 0;
	margin-left:6px;
}

.role-dropdown-primary-title, .role-dropdown-fallback-empty-title, .role-dropdown-fallback-title{
	position: absolute;
	border: 0;
	border-right: 0px;
	color: #808080;
	font-size: .8em;
	font-variant: small-caps;
	font-weight: bold;
	margin-left: 8px;
	height: 50px;
	line-height: 46px;
	margin-top: auto;
	margin-bottom: auto;
}

.enable-user-switch{
    margin: 10px;
	margin-right: 24px;
	position: absolute;
	right: 0px;
}

.role-dropdown-fallback-dropdown, .role-dropdown-fallback-empty-dropdown{
	position: absolute;
	display: inline-block;
	right: 0px;
	height: 48px;
	width: 265px;
	margin-right: 10px;
	border: 0;
	background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.4' height='6.1' viewBox='0 0 9.4 6.1'%3E%3Cpath fill='%23808080' d='M6.7 8.1 2 3.4 3.4 2 6.7 5.3 10 2l1.4 1.4Z' transform='translate%28-2 -2%29'/%3E%3C/svg%3E") no-repeat right .7em center/9.4px;
    border-radius: 0;
	padding: 0 2.1em 0em .75em;
	
	font-family: Roboto;
    font-size: 1em;
    font-weight: 400;
	line-height: 48px;
	text-align: right; /*firefox*/
	text-align-last:right;
	
	color: #808080;
	
	-webkit-appearance: none;
    -moz-appearance: none;
	appearance: none;
	margin-top: auto;
	margin-bottom: auto;
}

.role-dropdown-fallback-empty-dropdown {
	font-style: italic;
	overflow: visible;
}

.role-dropdown-option{
	color: #808080;
}

.role-dropdown-option-hide-text{
	display: none;
    visibility: hidden;
}

.role-dropdown-fallback-dropdown::-ms-expand, .role-dropdown-primary-dropdown::-ms-expand, .role-dropdown-fallback-empty-dropdown::-ms-expand {
	display: none;
}
.role-dropdown-fallback-dropdown:focus, .role-dropdown-primary-dropdown:focus, .role-dropdown-fallback-empty-dropdown:focus {
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}

.disabled{
	filter: opacity(50%)
}