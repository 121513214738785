.printedAgendaContainer{
    
}

.printedAgendaHeader{
    margin: auto;
    margin-top: .5in;
    justify-content: space-around;
    width: 90%;
    display: flex;
    flex-direction: row;
}

.printedAgendaLogo{
    height: 28pt;
}

.printedAgendaTitle{
    height: 28pt;
    line-height: 28pt;
    font-size: 20pt;
}

.printedAgendaBody{
    margin-top: 16pt;
    padding: 0%;
}

.table td {
    padding: 0%;
}

.table tr{
    vertical-align: top;
    -webkit-column-break-inside: avoid-page;
    -webkit-break-inside: avoid-page;
    page-break-inside: avoid-page;
    break-inside: avoid-page;
}

.printedAgendaItem{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 8pt;
    margin-bottom: 2pt;
}

.printedAgendaItemTime{
    font-size: 28pt;
    font-weight: bold;
    line-height: 36pt;
    margin: auto;
    margin-top: 10pt;
    margin-right: 8pt;
    text-align: end;
}

.printedAgendaItemContents{
    text-align: start;
}

.printedAgendaItemTitle{
    max-width: 6in;
    line-height: 52pt;
    font-size: 40pt;
    font-weight: bold;
}

.printedAgendaItemDescription{
    max-width: 6in;
    margin: 0;
    padding: 0;
    line-height: 16pt;
    font-size: 12pt;
}

.printedAgendaDivider{
    padding-left: 8pt;
    height: max-content;
    border-left: 1px solid black;
}