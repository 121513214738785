.editText{
    justify-content:center;
    display: flex;
}

.loginButton{
    margin-right: auto;
    margin-left: auto;
    justify-content:center;
    width: 350px;
    height: 48px;
    background: #00C4D8 0% 0% no-repeat padding-box;
    text-align: center;
    font: normal normal bold 10px/12px Arial;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
}

.loginButton:hover{
    background: #00a7b9 0% 0% no-repeat padding-box;
}

.forgotPasswordButton{
    position: absolute;
    cursor: pointer;
    bottom: 48px;
    width: 100%;
    text-align: center;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #0093A7;
    opacity: 1;
}

.forgotPasswordButton:hover{
    color: #006c7a
}