.disabled{
	filter: opacity(50%)
}

.sd-check-container{
    justify-content: center;
    display: flex;
}

.sd-check:checked[type=checkbox]{
    /* margin-left: 15px; */
}

.sd-check{
    width: 30px;
    height: 30px;
    background: #fff;
    border: 1px solid #b3b3b3;
}


.sd-check-label{
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    margin-left: 10px;
    color: #808080;
}


/*Checkbox css from react bootstrap, with modifications...*/

.sd-check{ /*Hide the system checkbox...*/
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #b3b3b3;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none
}

.sd-check[type=checkbox]{ /*Border...*/
    border-radius:.1em
}

.sd-check:active{
    -webkit-filter:brightness(90%);
    filter:brightness(90%)
}

.sd-check:focus{ /*Selection drop shadow...*/
    border-color:#808080;outline:0;box-shadow:0 0 0 .2rem rgba(255,255,255,.25)
}

.sd-check:checked{ /*Background...*/
    width: 30px;
    height: 30px;
    background: #fff;
    border: 1px solid #b3b3b3;
    background-color:#fff;
    background-repeat:no-repeat;
    background-position:center center;
    background-size:1em;
}

.sd-check:checked[type=checkbox]{ /*Check box icon...*/
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e %3crect width='16' height='16' transform='translate%280%29' fill='none'/%3e %3cpath fill='gray' transform='translate%280 2%29' d='M6.345 11.034 0 4.69 1.287 3.4 6.345 8.368 14.713 0 16 1.287Z'/%3e%3c/svg%3e")
}

/* */