.appointment-service-options-container{
    display: flex;   
    justify-content: center;
    padding-bottom: 24px;
}
.toggles-column{
    width: 50%;
    margin-top: 8px;
    text-align: left;
}
.weekly-options-column{
    margin-left: 24px;
    margin-right: 24px;
    text-align: left;
    width: 50%;
}

.weekly-options-switch-container{
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
}

.weekly-options-switch-text{
    width: 261px;
    height: 40px;
    font-family: Arial;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #808080;
}

.weekly-options-switch{
    margin: 5px; 
}

.primary-provider-value{
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    margin-left: 16px;
    color: #808080;
}

.primary-provider-error{
    line-height: 30px;
    font-size: 16px;
    color: red;
    margin-left: 16px;
}

.weekly-options{

}

.attendee-roles-toggle-container-unselected{
	width: 30px;
	height: 30px;
	border: 1px solid black;
	cursor: pointer;
}

.attendee-roles-toggle-container-selected{
	width: 30px;
	height: 30px;
	border: 1px solid #00C4D8;
	cursor: pointer;
}

.attendee-roles-toggle-container-selected:hover{
	background-color: #eee;
}

.attendee-roles-toggle-container-unselected:hover{
	background-color: #eee;
}


.attendee-roles-toggle{
	width: 22px;
	height: 17px;
    display: block;
    margin-left: auto;
    margin-right: auto; 
	margin-top: 6.5px
}

.attendee-roles-title{
    margin-top: 24px;
    font-family: Arial;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #808080;
}

.attendee-roles-description{
    font-family: Arial;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    color: #808080;
    margin-bottom: 24px;
}

.attendee-roles-create-button{
    width: 311px;
    height: 32px;
    margin-top: 12px;
    border-radius: 4px;
    background: #fff;
    border: 2px solid #00c4d8;
    font-family: Roboto;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    color: #808080;
}

.attendee-roles-create-button:disabled{
    filter: opacity(50%)
}

.checkbox-container{
    margin-left: 24px;
    width: fit-content;
    margin-top: 16px;
    margin-bottom: 16px;
}

.role-container{
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
}

.assign-provider-dropdown-fallback, .assign-provider-dropdown-fallback-empty{
	width: 281px;
}

/*Overrides for provider dropdown*/
.assign-provider-dropdown-fallback-dropdown, .assign-provider-dropdown-fallback-empty-dropdown {
    border-left:  1px solid #B3B3B3;
    border-right:  1px solid #B3B3B3;
    border-top:  1px solid #B3B3B3;
    border-bottom:  1px solid #B3B3B3;
}

.assign-primaries-button{
    width: 295px;
	height: 32px;
	margin-left: 16px;
    border-radius: 4px;
    background: #fff;
    border: 2px solid #00c4d8;
    font-family: Roboto;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    color: #808080;
}

.disabled{
    color: #bfbfbf;
}

.service-duration{
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #808080;
    color: #808080;
    margin-top: 6px;
    margin-right: 4px;
    font-size: 20px;
    width: 26px;
    height: 22px;
}

.service-duration-error{
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid red;
    color: red;
    margin-top: 6px;
    margin-right: 6px;
    font-size: 20px;
    width: 26px;
    height: 22px;
}

.service-duration-title{
    color: #808080;
    font-size: 16px;
    margin-right: 7px;
    margin-left: 24px;
    width: 22px;
    height: 22px;
}

.service-duration-text{
    color: #808080;
    font-size: 16px;
    width: 22px;
    height: 22px;
}

.service-duration-text-error{
    font-size: 16px;
    width: 22px;
    height: 22px;
    color: red;
}