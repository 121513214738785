.edit-item-container{
    margin: auto;
    width: 350px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.switch-container{
    margin-top: 24px;
    justify-content: center;
    width: 100%;
    display: flex;
}

.switch-text{
    text-align: left;
    width: 90%;
    height: 32px;
    line-height: 32px;
    font-family: Arial;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #808080;
}

.error{
    color: red;
}

.child-container{
    margin-top: 6px;
    font-size: 12px;
    text-align: left;
}

.itemDescriptionText{
    font-family: Arial;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #808080;
}

.itemNoteText{
    font-family: Arial;
    font-weight: normal;
    font-style: italic;
    font-size: 12px;
    text-align: left;
    color: #808080;
}
