/*1px = 0.062rem*/
.homeContainer-wide{
    display:flex;
    justify-content:space-between;
}

.homeContainer-skinny{
    margin: auto;
}

.optionsContainer{
    
}

.versionContainer{
    margin-left: 8px;
    margin-bottom: 8px;
}

.alertContainer{
    width: 100%;
    max-width: 93rem;
}