/*1px = 0.062rem*/
.announcementTitle{
    font-family: Arial;
    font-weight: bold;
    font-size: 1.24rem;
    line-height: 1.86rem;
    text-align: left;
    color: #00c4d8;
}

.rootContainer{
    margin: 2.79rem;
}

.messageContainer{
    position: relative;
    margin-top: .496rem;
    margin-bottom: .496rem;
    border-radius: .248rem;
    padding-left: 1.612rem;
    padding-right: 1.612rem;
    padding-top: .496rem;
    padding-bottom: .992rem;
    background: #fff;
    border: 1px solid #00c4d8;
}

.messageAge{
    font-family: Arial;
    font-weight: normal;
    font-style: italic;
    font-size: 1.24rem;
    line-height: 1.86rem;
    text-align: left;
    color: #808080;
}

.messageBody{
    margin-top: 1.984rem;
    font-family: Arial;
    font-weight: normal;
    font-size: 1.24rem;
    line-height: 1.86rem;
    text-align: left;
    color: #808080;
}

.messageDeleteIcon{
    cursor: pointer;
    position: absolute;
    top: .496rem;
    right: .496rem;
}