.schedule-title{
	font-size: 14px;
	color: #00C4D8;
	font-family: "Roboto";
	font-weight: bold
}
.schedule-subtitle{
	font-size: 12px;
	font-family: "Roboto";
}
.add-button{
	width: 275px;
	height: 48px;
	background-color: #FFFFFF;
	border: 2px solid #989898;
	border-radius: 4px
}
.schedule-button{
	width: 275px;
	height: 48px;
	background-color: #F2F2F2;
	border: 1px solid #E2DFDF;
	border-radius: 4px;
	margin-bottom: 12px;
}
.schedule-column{
	width: 275px;
	max-width: 275px;
	padding: 0px;
	margin: 8px
}
.zms-dropdown{
	width: 278px;
	height: 50px;
	text-align-last: right;
	top: 0;
	left: 0;
	font-size: 14px;
	color: #808080;
	border: 1px solid #00C4D8;
	border-radius: 4px;
	font-family: "Roboto";


	display: inline-block;
	background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.4' height='6.1' viewBox='0 0 9.4 6.1'%3E%3Cpath fill='%23808080' d='M6.7 8.1 2 3.4 3.4 2 6.7 5.3 10 2l1.4 1.4Z' transform='translate%28-2 -2%29'/%3E%3C/svg%3E") no-repeat right .7em center/9.4px;
	padding: 0 2.1em 0em .75em;
	
	-webkit-appearance: none;
    -moz-appearance: none;
	appearance: none;
	margin-top: auto;
	margin-bottom: auto;
}
.zms-dropdown-title{
	margin-left: 8px;
	height: 50px;
	line-height: 50px;
	z-index: 1;
	position: absolute;
	font-size: 10px;
	font-family: "Roboto";
	font-weight: bold;
	color: #808080
}
.zms-dropdown-option{
	font-family: "Roboto";
	color: #808080;
	font-size: 14px
}

/**Overrides to clean-switch.css**/
.lightishBlue input[type="checkbox"]:checked + .switcher {
	background: #81c1d6; /*this is body of switch that should be lighter color*/
	padding-top: 8px;
	padding-right: 16px;
}
.lightishBlue input[type="checkbox"]:checked + .switcher:before {
	background: #00C4D8; /*this is btn of switch that should be darker color*/
	padding-top: 8px;
	padding-right: 16px;
}