.loginContainer{
    position: relative;
    width: 446px;
    height: 517px;
    margin: auto;
    justify-content:center;
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #00C4D8;
    opacity: 1;
}

.loginContainer div + div{
    margin-top: 24px;
}

.loginContainer div + button{
    margin-top: 24px;
}

.loginLogoContainer{
    position: absolute;
    top: 48px;
    width: 100%;
    justify-content: center;
}

.loginLogo{
   height: 80px;
   width: 100%;
}