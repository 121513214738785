.background{
    display: flex;
    justify-content:center;
    height: calc(100vh);
    background-image: radial-gradient(#00C4D8 50%, transparent 0%);
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
}

.overlay{
    position: absolute;
    background: transparent linear-gradient(127deg, #FFFFFFCC 0%, #FFFFFFE6 4%, #FFFFFF 50%, #FFFFFFE6 95%, #FFFFFFCC 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    height: 100%;
    width: 100%;
}