.confirmationContainer{
    padding: 24px;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    width: 500px;
    border-radius: 15px;
    border: 2px solid #00C4D8;
    box-shadow: 5px 5px #80808050;
}

.confirmationWarning{

}

.confirmationInfoRuntime{
    font-size: 12px;
    font-style: italic;
    color: red;
}

.confirmationButtonContainer{
    margin-top: 24px;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 24px;
}



.confirmationButton{

}