.day-selected{
    width: 42px;
    height: 42px;
    position: absolute;
}
.day-unselected{
    width: 42px;
    height: 42px;
    position: absolute;
}

.day-disabled{
    width: 42px;
    height: 42px;
    position: absolute;
}

.day-disabled-text{
    height: 30px;
    width: 30px;
    padding-left: 12px;
    padding-top: 6px;
    line-height: 30px;
    text-align: center;
    position: relative;
    pointer-events: none;
    color: #80808038;
    font-size: 10px;
    font-weight: bold
}

.day-selected-text{
    height: 30px;
    width: 30px;
    padding-left: 12px;
    padding-top: 6px;
    line-height: 30px;
    text-align: center;
    position: relative;
    pointer-events: none;
    color: white;
    font-size: 10px;
    font-weight: bold
}

.day-unselected-text{
    height: 30px;
    width: 30px;
    padding-left: 12px;
    padding-top: 6px;
    line-height: 30px;
    text-align: center;
    position: relative;
    pointer-events: none;
    color: #BFBFBF;
    font-size: 10px;
    font-weight: bold
}

.day-picker-label{
    position: absolute;
    left: 100px;
    margin-top: 8px;
}