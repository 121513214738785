.services-text{
	margin-top: 24px;
	margin-bottom: 17px;
	font-family: roboto;
	font-weight: bold;
	color: #00C4D8;
	margin-left: 4px;
}

.roles-text{
	margin-top: 8px;
	margin-left: 4px;
	font-family: "Segoe UI";
	font-weight: normal;
	font-style: italic;
	font-size: 14px;
	text-align: left;
	color: #808080;
}

.colorPickerUserButtonContainer{
    justify-content: center;
    display: flex;
	width: 100%;
	z-index: 10;
}

.colorPickerUserButton{
    margin-top: 24px;
    margin-bottom: 24px;
    width: 350px;
    height: 48px;
    border-radius: 4px;
    border: none;
    font-family: Arial;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
	color: #fff;
	z-index: 10;
}

.colorPickerUser{
    position: absolute;
    left: 20%;
	top: 250px;
	z-index: 10;
}