.reasonDropdown{
    position: absolute;
    background: #ffffff;
    padding: 8px;
    font-size: 14px;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    z-index: 2;
}

.alertIcon{
    width: 16px;
    height: 16px;
    margin-right: 20px;
}

.alertIconHidden{
    composes: alertIcon;
    visibility: hidden;
}

.alertIconSelectable{
    composes: alertIcon;
    cursor: pointer;
}