.agendaTitleContainer{
    display: flex; 
    flex-direction: row; 
    justify-content: center; 
    flex-wrap: wrap; 
    margin-top: 8px;
}

.agendaTitle{
    line-height: 32px;
    font-size: 24px;
}

.selectProviderButton{
    position: relative;
    max-height: 32px;
    margin-left: 8px; 
    padding: 4px;
    border: 2px solid #00C4D8;
    border-radius: 4px;
}

.selectProviderButton:hover{
    opacity:0.5;
}

.selectProviderButton:focus{
    opacity:0.5;
}