.navigation-header-container{
    width: 100%;
    height: 40px;
    background-color: #f2f2f2;
}

.navigation-header-options-container{
    position: absolute;
    right: 32px;
    height: 40px;
    display: flex;
    justify-content:center;
}

.navigation-header-option{
    padding: 0;
    border: none;
    background: none;
    margin-left: 8px;
    margin-right: 8px;
    font-family: Arial;
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    text-align: right;
    color: #00c4d8;
    vertical-align: middle;
    cursor: pointer;
}

.navigation-header-option-Cancel{
    color: #808080;
}

.navigation-header-option-Delete{
    color: #d80000;
}

.navigation-header-forward-button{
    padding: 0;
    border: none;
    background: none;
    position: absolute;
    right: 32px;
    font-family: Arial;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    color: #00c4d8;
    border: 0px;

    vertical-align: middle;
    height: 40px;
    line-height: 40px;
    margin-right: 32px;
    cursor: pointer;
}

.navigation-header-back-button{
    padding: 0;
    border: none;
    background: none;
    position: absolute;
    left: 32px;
    font-family: Arial;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #00c4d8; 
    border: 0px;
    
    vertical-align: middle;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}

.navigation-header-title{
    height: 40px; 
    text-align: center; 
    line-height: 40px;
}

.disabled{
    color: #bfbfbf;
    pointer-events: none;
}