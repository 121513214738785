/*1px = 0.062rem*/
.role-list-container{
    margin-top: 4.03rem;
    padding: .992rem;
    display: flex;
    justify-content:center;
}

.role-list-button{
    margin-left: .496rem;
    margin-right: .496rem;
    width: 10.478rem;
    height: 2.976rem;
    border-radius: 4px;
    background: #f2f2f2;
    border: 1px solid #e2dfdf;
}