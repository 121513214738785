.add-eligible-provider-button{
	width: 295px;
	height: 32px;
	margin-left: 16px;
	background: white;
	border: 1px solid #B3B3B3;
	border-radius: 2px;
	font-family: Roboto;
	font-weight: bold;
	font-size: 10px;
	text-align: center;
	color: #808080;
}

.assign-provider-dropdown-fallback{
	padding-left: 16px
}

.assign-provider-dropdown-primary-title, .assign-provider-dropdown-fallback-empty-title, .assign-provider-dropdown-fallback-title{
	position: absolute;
	border: 0;
	border-right: 0px;
	color: #808080;
	font-size: .8em;
	font-variant: small-caps;
	font-weight: bold;
	margin-left: 8px;
	height: calc(1.5em + .45em + 2px);
	line-height: calc(1.5em + .45em + 2px);
}

.assign-provider-dropdown-fallback-delete-container, .assign-provider-dropdown-fallback-empty-delete-container{
	background-color: #fff;
	width: 30px;
	height: 29px;
	border: 1px solid #B3B3B3;
	border-radius: 0 .25em .25em 0;
	cursor: pointer;
}

.assign-provider-dropdown-fallback-delete, .assign-provider-dropdown-fallback-empty-delete{
	width: 16px;
	height: 16px;
	display: block;
    margin-left: auto;
    margin-right: auto; 
	margin-top: 7px
}

.assign-provider-dropdown-fallback-delete-container:hover, .assign-provider-dropdown-fallback-empty-delete-container:hover{
	background-color: #eee;
}

.assign-provider-dropdown-primary-dropdown, .assign-provider-dropdown-fallback-empty-dropdown, .assign-provider-dropdown-fallback-dropdown {
	width: 265px;
	display: inline-block;
    height: calc(1.5em + .75em + 2px);
    padding: .375em 2.1em .375em .75em;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
	color: #808080;
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.4' height='6.1' viewBox='0 0 9.4 6.1'%3E%3Cpath fill='%23808080' d='M6.7 8.1 2 3.4 3.4 2 6.7 5.3 10 2l1.4 1.4Z' transform='translate%28-2 -2%29'/%3E%3C/svg%3E") no-repeat right .7em center/9.4px;
	
	border:  0px;
    border-radius: .25em 0 0 .25em;
    -webkit-appearance: none;
    -moz-appearance: none;
	appearance: none;
	text-align: right; /*firefox*/
	text-align-last:right;
}

.assign-provider-dropdown-fallback-empty-dropdown{
	color: #bfbfbf;
	font-style: italic;
}

.assign-provider-dropdown-primary-dropdown, .assign-provider-dropdown-fallback-empty-dropdown{
	margin-left: 16px;
}

.assign-provider-dropdown-option{
	color: #808080;
}

.assign-provider-dropdown-option-hide-text{
	display: none;
    visibility: hidden;
}

.assign-provider-dropdown-fallback-dropdown::-ms-expand, .assign-provider-dropdown-primary-dropdown::-ms-expand, .assign-provider-dropdown-fallback-empty-dropdown::-ms-expand {
	display: none;
}
.assign-provider-dropdown-fallback-dropdown:hover, .assign-provider-dropdown-primary-dropdown:hover, .assign-provider-dropdown-fallback-empty-dropdown:hover {
	border-color: #888;
}
.assign-provider-dropdown-fallback-dropdown:focus, .assign-provider-dropdown-primary-dropdown:focus, .assign-provider-dropdown-fallback-empty-dropdown:focus {
	border-color: #aaa;
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}

.disabled{
	filter: opacity(50%)
}